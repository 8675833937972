import React from 'react';

export const ControlPanel: React.FC = () => {
  return (
    <div className="h-[100%] w-[100%] flex flex-col gap-5">
      <h1 className="text-2xl font-heading font-bold text-center">
        Relevant stages
      </h1>
      <ol className="list-decimal ml-8">
        <li>
          <text className="text-xl">Fabrication</text>
        </li>
        <li>
          <text className="text-xl">Provision</text>
        </li>
      </ol>
    </div>
  );
};
