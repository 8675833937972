import React from 'react';
import { ControlPanel, MapChart } from './components';

const App: React.FC = () => {
  return (
    <div className="h-full w-full flex flex-col justify-center items-center py-10 gap-10">
      <div className="flex flex-col px-20 gap-5">
        <h1 className="text-4xl font-heading text-center font-bold">
          AI Compute Governance is a big deal
        </h1>
        <p className="text-xl text-center">
          <b>Core claim:</b> Compute governance is feasible because advanced AI
          computing infrastructure cannot be <b>hidden</b> and cannot escape{' '}
          <b>existing supply chain chokepoints</b>. Not convinced? Take a look
          at the map
        </p>
      </div>
      <div className="w-[100%] flex flex-1 flex-row">
        <div className="w-[75%] flex flex-col justify-center items-center">
          <MapChart />
        </div>
        <div className="w-[25%]">
          <ControlPanel />
        </div>
      </div>
    </div>
  );
};

export default App;
