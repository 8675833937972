import React from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';

const geoUrl = 'https://unpkg.com/world-atlas@2.0.2/countries-110m.json';
// const CHOSEN_COUNTRY = 'China';
// DEPLOY

export const MapChart: React.FC = () => {
  return (
    <div className="h-[600px] w-[800px]">
      <ComposableMap>
        <ZoomableGroup>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#F5F5F5"
                    stroke="black"
                    strokeWidth={0.5}
                    /*{...(name === CHOSEN_COUNTRY && { fill: 'red' })}*/
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};
